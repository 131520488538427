import { API, graphqlOperation } from 'aws-amplify'
import { logsByDate } from '../graphql/queries'
import { createLog } from '../graphql/mutations'

export const getLogs = async (nextToken = null) => {
  try {
    const params = {
      type: 'userLog',
      sortDirection: "DESC",
      limit: 20
    }
    if (nextToken) {
      params.nextToken = nextToken
    }
    const logsData = await API.graphql(
      graphqlOperation(logsByDate, params)
    );
    return {
      logs: logsData.data.logsByDate.items,
      nextToken: logsData.data.logsByDate.nextToken
    }
  } catch(error) {
    console.error('Error en conseguir dispositivos: ', error)
    return {
      logs: [],
      nextToken: null
    }
  }
}

export const addNewLog = async (log) => {
  log.createdAt = new Date().toLocaleString()
  try {
    await API.graphql({
      query: createLog,
      variables: {
        input: log
      }
    })
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}
