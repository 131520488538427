import { createSlice } from '@reduxjs/toolkit'

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    list: []
  },
  reducers: {
    setNotifications: (state, action) => {
      const { payload: notifications } = action
      state.list = notifications
    },
    addNotification: (state, action) => {
      const { payload: notification } = action
      const { list } = state
      if (list.find(savedNotification => savedNotification.id === notification.id) === undefined) {
        let listAux = list
        listAux.push(notification)
        state.list = listAux
      }
    },
    updateNotification: (state, action) => {
      const { payload: {
        updatedData,
        notificationId
      } } = action
      state.list = state.list.map(savedNotification => {
        if (savedNotification.id === notificationId) {
          return {...savedNotification, ...updatedData}
        }
        return savedNotification
      })
    },
    removeNotification: (state, action) => {
      const { payload: notification } = action
      const { list } = state
      let listAux = list.filter((row) => row.id !== notification.id)
      state.list = listAux
    },
    resetNotifications: (state) => {
      state.list = []
    }
  }
})

export const {
  setNotifications,
  addNotification,
  updateNotification,
  removeNotification,
  resetNotifications
} = notificationsSlice.actions

export default notificationsSlice.reducer
