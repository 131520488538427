import { createSlice } from '@reduxjs/toolkit'

export const schedulesSlice = createSlice({
  name: 'schedules',
  initialState: {
    list: []
  },
  reducers: {
    setSchedules: (state, action) => {
      const { payload: schedules } = action
      state.list = schedules
    },
    addSchedule: (state, action) => {
      const { payload: schedule } = action
      const { list } = state
      if (list.find(savedSchedule => savedSchedule.id === schedule.id) === undefined) {
        let listAux = list
        listAux.push(schedule)
        state.list = listAux
      }
    },
    updateSchedule: (state, action) => {
      const { payload: {
        updatedData,
        scheduleId
      } } = action
      state.list = state.list.map(savedSchedule => {
        if (savedSchedule.id === scheduleId) {
          return {...savedSchedule, ...updatedData}
        }
        return savedSchedule
      })
    },
    removeSchedule: (state, action) => {
      const { payload: schedule } = action
      const { list } = state
      let listAux = list.filter((row) => row.id !== schedule.id)
      state.list = listAux
    },
    resetSchedules: (state) => {
      state.list = []
    }
  }
})

export const {
  setSchedules,
  addSchedule,
  updateSchedule,
  removeSchedule,
  resetSchedules
} = schedulesSlice.actions

export default schedulesSlice.reducer
