/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:cb419266-03c9-4913-8aec-c0bf5966fb00",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_W1WWgviC8",
    "aws_user_pools_web_client_id": "2b6i7ge52qv0en8fj5bku25m43",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://u7fgsw9dqd.execute-api.us-west-2.amazonaws.com/amplify",
            "region": "us-west-2"
        },
        {
            "name": "AttachIOTPolicy",
            "endpoint": "https://50lemfq3ei.execute-api.us-west-2.amazonaws.com/amplify",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ihgxmoort5hzjm6n2bzgk7mzpu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "sysmecdotcontrol118cbd6492da43aea8f3853eef97690150906-amplify",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
