import firebase from 'firebase/compat/app';
import { getMessaging, getToken } from 'firebase/messaging'

export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyBkolYKsod6L4B_btLZxtY60NfpatNBy5o",
    authDomain: "sysmec-dotcontrol.firebaseapp.com",
    projectId: "sysmec-dotcontrol",
    storageBucket: "sysmec-dotcontrol.appspot.com",
    messagingSenderId: "859149818233",
    appId: "1:859149818233:web:7cadc541644c02e09f37a8"
  });
}

export const askForPermissionToReceiveNotifications = async () => {
  try {
    const messaging = getMessaging();
    //await messaging.requestPermission();
    const token = await getToken(messaging, { vapidKey: "BOpyoQipzJ9qyqOSSANik9Qox1Y4PxkZ_Zxm_0goTny-5zfn_Lijo9ZCHwOrsWB7Q2z_ODEevIAYs5tIeU8q-e0"});
    console.log('Your token is:', token);

    return token;
  } catch (error) {
    console.error(error);
  }
}
