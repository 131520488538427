import { createSlice } from '@reduxjs/toolkit'

export const logsSlice = createSlice({
  name: 'logs',
  initialState: {
    list: [],
    nextToken: null
  },
  reducers: {
    setLogs: (state, action) => {
      const {
        payload: {
          logs,
          nextToken
        }
      } = action
      state.list = logs
      state.nextToken = nextToken
    },
    addLogs: (state, action) => {
      const {
        payload: {
          logs,
          nextToken
        }
      } = action
      const actualList = state.list
      state.list = [...actualList, ...logs]
      state.nextToken = nextToken
    },
    addLog: (state, action) => {
      const { payload: log } = action
      const { list } = state
      if (list.find(savedLog => savedLog.id === log.id) === undefined) {
        let listAux = list
        listAux.unshift(log)
        state.list = listAux
      }
    },
    resetLogs: (state) => {
      state.list = []
      state.nextToken = null
    }
  }
})

export const {
  setLogs,
  addLogs,
  addLog,
  resetLogs
} = logsSlice.actions

export default logsSlice.reducer
