/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateImage = /* GraphQL */ `
  subscription OnCreateImage {
    onCreateImage {
      id
      name
      url
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateImage = /* GraphQL */ `
  subscription OnUpdateImage {
    onUpdateImage {
      id
      name
      url
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteImage = /* GraphQL */ `
  subscription OnDeleteImage {
    onDeleteImage {
      id
      name
      url
      createdAt
      updatedAt
    }
  }
`;
export const onCreateThing = /* GraphQL */ `
  subscription OnCreateThing {
    onCreateThing {
      id
      name
      shadowName
      maxFuelMM
      hoursUsed
      scheduleId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateThing = /* GraphQL */ `
  subscription OnUpdateThing {
    onUpdateThing {
      id
      name
      shadowName
      maxFuelMM
      hoursUsed
      scheduleId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteThing = /* GraphQL */ `
  subscription OnDeleteThing {
    onDeleteThing {
      id
      name
      shadowName
      maxFuelMM
      hoursUsed
      scheduleId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      deviceVariable
      logic
      limitValue
      message
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      deviceVariable
      logic
      limitValue
      message
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      deviceVariable
      logic
      limitValue
      message
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLog = /* GraphQL */ `
  subscription OnCreateLog {
    onCreateLog {
      id
      type
      user
      action
      description
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLog = /* GraphQL */ `
  subscription OnUpdateLog {
    onUpdateLog {
      id
      type
      user
      action
      description
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLog = /* GraphQL */ `
  subscription OnDeleteLog {
    onDeleteLog {
      id
      type
      user
      action
      description
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSchedule = /* GraphQL */ `
  subscription OnCreateSchedule {
    onCreateSchedule {
      id
      allTheTime
      beginHour
      endHour
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSchedule = /* GraphQL */ `
  subscription OnUpdateSchedule {
    onUpdateSchedule {
      id
      allTheTime
      beginHour
      endHour
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSchedule = /* GraphQL */ `
  subscription OnDeleteSchedule {
    onDeleteSchedule {
      id
      allTheTime
      beginHour
      endHour
      createdAt
      updatedAt
    }
  }
`;
