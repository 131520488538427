import { createSlice } from '@reduxjs/toolkit'

export const devicesTypesSlice = createSlice({
  name: 'devicesTypes',
  initialState: {
    list: [],
    isLoaded: false
  },
  reducers: {
    setDeviceTypes: (state, action) => {
      const { payload: deviceTypes } = action
      state.list = deviceTypes
    },
    addDeviceType: (state, action) => {
      const { payload: deviceType } = action
      const { list } = state
      let listAux = list
      listAux.push(deviceType)
      state.list = listAux
    },
    removeDeviceType: (state, action) => {
      const { payload: deviceType } = action
      const { list } = state
      let listAux = list.filter((row) => row.id !== deviceType.id)
      state.list = listAux
    },
    confirmLoad: (state) => {
      state.isLoaded = true
    },
    resetDevicesTypes: (state) => {
      state.list = []
      state.isLoaded = false
    }
  }
})

export const {
  setDeviceTypes,
  addDeviceType,
  removeDeviceType,
  confirmLoad,
  resetDevicesTypes
} = devicesTypesSlice.actions

export default devicesTypesSlice.reducer
