import { Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import awsExports from "./aws-exports";
import { PubSub} from 'aws-amplify'
import { addDevice, updateDevice, removeDevice } from './slices/devicesSlice'
import { addThing, removeThing } from './slices/thingsSlice'
import { addNotification, removeNotification } from './slices/notificationsSlice'
import { addLog } from './slices/logsSlice'
import { addSchedule } from './slices/schedulesSlice'
import {
  onCreateThing,
  onDeleteThing,
  onCreateNotification,
  onDeleteNotification,
  onCreateLog,
  onCreateSchedule
} from './graphql/subscriptions'
import { addNewLog } from './api/logs'

import Logo from "./assets/images/dot.png";

import './App.scss';
import { ProtectedRoute } from './ProtectRoute';

const VisitantsDispoList = lazy(() => import("./VisitantsDispoList"));
const DispoList = lazy(() => import("./DispoList"));
const Login = lazy(() => import("./Login"));
const Users = lazy(() => import("./Users"));
const Devices = lazy(() => import("./Devices/index"));
const DevicesTypes = lazy(() => import("./DevicesTypes/index"));
const Notifications = lazy(() => import("./Notifications/index"));
const Logs = lazy(() => import("./Logs/index"));
const Schedules = lazy(() => import("./Schedules/index"));
const Password = lazy(() => import("./Password/index"));

Amplify.configure(awsExports);
Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: process.env.REACT_APP_AWS_PUBSUB_REGION,
  aws_pubsub_endpoint: process.env.REACT_APP_AWS_PUBSUB_ENDPOINT,
}));

function App(){
  const dispatch = useDispatch()

  const things = useSelector(state => state.things.list)
  const schedules = useSelector(state => state.schedules.list)
  const notifications = useSelector(state => state.notifications.list)
  const isLogged = useSelector(state => state.user.logged)
  const [subscribedThings, setSubscribedThings] = useState([])

  const Loading = () => {
    return (
      <div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <p style={{ textAlign: 'center', color: '#ffa400', fontSize: '2rem' }}>Cargando...</p>
      </div>
    );
  }

  const getDeviceSchedule = (deviceId) => {
    const thing = things.find(t => t.name === deviceId)
    if (thing.scheduleId) {
      const schedule = schedules.find(s => s.id === thing.scheduleId)
      return schedule
    }
    return undefined
  }

  const getDeviceHours = (deviceId) => {
    const thing = things.find(t => t.name === deviceId)
    return thing.hoursUsed
  }

  const checkForAlerts = (deviceData, deviceId) => {
    if (deviceData.std === "OFF") {
      const deviceSchedule = getDeviceSchedule(deviceId)
      if (deviceSchedule) {
        if (deviceSchedule.allTheTime) {
          new Notification(`El dispositivo ${deviceId} se ha apagado`, {
            body: "Haga click aquí para más detalles",
          })
        } else {
          const {
            beginHour,
            endHour
          } = deviceSchedule
          const now = new Date()
          const hour = `${now.getHours()}:${now.getMinutes()}`
          if (beginHour < hour && endHour > hour) {
            new Notification(`El dispositivo ${deviceId} se ha apagado`, {
              body: "Haga click aquí para más detalles",
            })
          }
        }
      }
    }
    const deviceVars = {
      fuel: deviceData.comb,
      battery: deviceData.bat,
      temp: deviceData.temp,
      hours: getDeviceHours(deviceId)
    }
    notifications.forEach(notification => {
      const {
        deviceVariable,
        logic,
        limitValue,
        message
      } = notification
      let showNotification = false
      if (logic === ">") {
        if (deviceVars[deviceVariable] > limitValue) {
          showNotification = true
        }
      } else {
        if (deviceVars[deviceVariable] < limitValue) {
          showNotification = true
        }
      }
      if (showNotification) {
        new Notification(message, {
          body: `Dispositivos afectados: ${deviceId}`,
        })
      }
    })
  }

  const subscribeToDevices = () => {
    console.log("subscribing to things!")
    const subscribedThingsAux = subscribedThings
    things.forEach(thing => {
      const {
        name,
        shadowName
      } = thing
      if (!(name in subscribedThingsAux)) {
        console.log(`subscribing to ${name}`)
        const baseTopic = `$aws/things/${name}/shadow/name/${shadowName}`;
        PubSub.subscribe(baseTopic + "/get/accepted").subscribe(
          response => {
            try {
              const device = {
                ...response.value.state.reported,
                isUpdating: false,
                isOpen: true
              }
              dispatch(addDevice(device))
            } catch (error) {
              console.error(error)
            }
          },
          error => {
            console.error(error)
          },
          () => {
            console.log('Ya no se reciben mensajes')
          }
        )
        PubSub.subscribe(baseTopic + "/update/accepted").subscribe(
          response => {
            const deviceData = response.value.state.reported
            if (deviceData) {
              deviceData.isUpdating = false
              dispatch(updateDevice({
                updatedData: deviceData,
                deviceId: name
              }))
              let action = ''
              let description = ''
              if (deviceData.std === "OFF") {
                action = 'turnOff'
                description = `El dispositivo ${name} se ha apagado.`
              } else {
                action = 'turnOn'
                description = `El dispositivo ${name} se ha encendido.`
              }
              const logData = {
                type: 'userLog',
                user: '-',
                action,
                description
              }
              addNewLog(logData)
              checkForAlerts(deviceData, name)
            }
          },
          error => {
            console.log("Error en actualización :c")
            console.error(error)
            dispatch(updateDevice({
              updatedData: {isUpdating: false},
              deviceId: name
            }))
          },
          () => {
            console.log('Ya no se reciben mensajes')
          }
        )
        PubSub.subscribe(baseTopic + "/update/rejected").subscribe(
          response => {
            console.log("Actualización rechazada!")
            console.log(response)
            dispatch(updateDevice({
              updatedData: {isUpdating: false},
              deviceId: name
            }))
          },
          error => {
            console.log("Error en actualización rechazada :c")
            console.error(error)
            dispatch(updateDevice({
              updatedData: {isUpdating: false},
              deviceId: name
            }))
          },
          () => {
            console.log('Ya no se reciben mensajes')
          }
        )
        subscribedThingsAux.push(name)
      }
    })
    if (subscribedThingsAux.length > subscribedThings.length) {
      setSubscribedThings(subscribedThingsAux)
    }
  }

  useEffect(()=>{
    subscribeToDevices()
  }, [things])

  useEffect(()=>{
    if (isLogged) {
      API.graphql(
        graphqlOperation(onCreateThing)
      ).subscribe({
        next: ({provider, value}) => dispatch(addThing(value.data.onCreateThing)),
        error: error => console.error(error)
      })
      API.graphql(
        graphqlOperation(onDeleteThing)
      ).subscribe({
        next: ({provider, value}) => {
          dispatch(removeThing(value.data.onDeleteThing))
          dispatch(removeDevice({id: value.data.onDeleteThing.name}))
        },
        error: error => console.error(error)
      })
      API.graphql(
        graphqlOperation(onCreateNotification)
      ).subscribe({
        next: ({provider, value}) => dispatch(addNotification(value.data.onCreateNotification)),
        error: error => console.error(error)
      })
      API.graphql(
        graphqlOperation(onDeleteNotification)
      ).subscribe({
        next: ({provider, value}) => dispatch(removeNotification(value.data.onDeleteNotification)),
        error: error => console.error(error)
      })
      API.graphql(
        graphqlOperation(onCreateLog)
      ).subscribe({
        next: ({provider, value}) => dispatch(addLog(value.data.onCreateLog)),
        error: error => console.error(error)
      })
      API.graphql(
        graphqlOperation(onCreateSchedule)
      ).subscribe({
        next: ({provider, value}) => dispatch(addSchedule(value.data.onCreateSchedule)),
        error: error => console.error(error)
      })
    }
  }, [])

  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Switch>
          <ProtectedRoute exact path="/" component={DispoList} />
          <ProtectedRoute exact path="/dispositivos" component={Devices} />
          <ProtectedRoute exact path="/tipos-dispositivos" component={DevicesTypes} />
          <ProtectedRoute exact path="/notificaciones" component={Notifications} />
          <ProtectedRoute exact path="/horarios" component={Schedules} />
          <ProtectedRoute exact path="/usuarios" component={Users} />
          <ProtectedRoute exact path="/logs" component={Logs} />
          <Route exact path="/visitantes" component={VisitantsDispoList} />
          <Route exact path="/recuperar-password" component={Password} />
          <Route path="/login" exact>
            <Login subscribeToDevices={subscribeToDevices} />
          </Route>
          <Route path="/contacto" exact>
            <Contact />
          </Route>
          <Route path="*">
            <Login subscribeToDevices={subscribeToDevices} />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  );
}

const Contact = () => {
  return (
    <>
      <div className="container-lg d-flex flex-column justify-content-center text-center">
        <Link to="/">
          <img src={Logo} alt="Logo dot" width="300" className="mx-auto"/>
        </Link>
        <div className="col-md-4 d-flex flex-column text-start mx-auto">
          <strong>Correo</strong>
          <a href="mailto:contacto@sysmec.cl">contacto@sysmec.cl</a>
          <strong className="mt-3">Teléfono</strong>
          <a href="tel:+56985811612">+56 9 8581 1612</a>
          <a href="tel:+56976435111">+56 9 7643 5111</a>
          <strong className="mt-3">Correo</strong>
          <p className="mb-1">Av Providencia 1208, oficina 1603</p>
          <p>8 am to 7 pm</p>
        </div>
        <Link to="/" className="mt-3">Ir a inicio</Link>
      </div>
    </>
  );
}

//export default withAuthenticator(App);
export default App;
