import { createSlice } from '@reduxjs/toolkit'

export const thingsSlice = createSlice({
  name: 'things',
  initialState: {
    list: []
  },
  reducers: {
    setThings: (state, action) => {
      const { payload: things } = action
      state.list = things
    },
    addThing: (state, action) => {
      const { payload: thing } = action
      const { list } = state
      if (list.find(savedThing => savedThing.id === thing.id) === undefined) {
        let listAux = list
        listAux.push(thing)
        state.list = listAux
      }
    },
    updateThing: (state, action) => {
      const { payload: {
        updatedData,
        thingId
      } } = action
      state.list = state.list.map(savedThing => {
        if (savedThing.id === thingId) {
          return {...savedThing, ...updatedData}
        }
        return savedThing
      })
    },
    removeThing: (state, action) => {
      const { payload: thing } = action
      const { list } = state
      let listAux = list.filter((row) => row.id !== thing.id)
      state.list = listAux
    },
    resetThings: (state) => {
      state.list = []
    }
  }
})

export const {
  setThings,
  addThing,
  updateThing,
  removeThing,
  resetThings
} = thingsSlice.actions

export default thingsSlice.reducer
