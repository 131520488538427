export const sortById = (a, b) => {
  if (a.id < b.id) {
    return -1
  }
  if (a.id > b.id) {
    return 1
  }
  return 0
}
export const getColor = (level) => {
  if (level > 80){ return "success"; }
  if (level > 50){ return "warning"; }
  if (level > 45){ return "middle"; }
  return "danger";
}

export const getHoursColor = (level) => {
  if (level < 20){ return "success"; }
  if (level < 50){ return "warning"; }
  if (level < 70){ return "middle"; }
  return "danger";
}

export const getTempColor = (level) => {
  if (level > 10){ return "success"; }
  if (level > 5){ return "warning"; }
  if (level > 0){ return "middle"; }
  return "danger";
}

export const getBatteryColor = (level) => {
  if (level > 11){ return "success"; }
  return "danger";
}

export const scrollToElement = (id) => {
  const titleElement = document.getElementById(id)
  titleElement.scrollIntoView({ behavior: 'smooth' })
}

