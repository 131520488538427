import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const history = useHistory();
    const user = useSelector(state => state.user)
    const {logged} = user;

    return (
        <Route
            { ...rest }
            render={props => {
                if(logged){
                    return <Component { ...props } />
                }else{
                    history.push("/login");
                }
            }}
        />
    );
}