import { createSlice } from '@reduxjs/toolkit'
import { sortById } from '../helpers'

export const devicesSlice = createSlice({
  name: 'devices',
  initialState: {
    list: [],
    isLoaded: false
  },
  reducers: {
    addDevice: (state, action) => {
      const { payload: device } = action
      const { list } = state
      if (list.find(savedDevice => savedDevice.id === device.id) === undefined) {
        let listAux = list
        listAux.push(device)
        listAux.sort(sortById)
        state.list = listAux
      }
    },
    updateDevice: (state, action) => {
      const { payload: {
        updatedData,
        deviceId
      } } = action
      state.list = state.list.map(savedDevice => {
        if (savedDevice.id === deviceId) {
          return {...savedDevice, ...updatedData}
        }
        return savedDevice
      })
    },
    removeDevice: (state, action) => {
      const { payload: device } = action
      const { list } = state
      let listAux = list.filter((row) => row.id !== device.id)
      state.list = listAux
    },
    updateDeviceShow: (state, action) => {
      const { payload: {
        deviceId
      } } = action
      state.list = state.list.map(savedDevice => {
        if (savedDevice.id === deviceId) {
          const auxDevice = {...savedDevice, isOpen: !savedDevice.isOpen}
          return auxDevice;
        }
        return savedDevice
      })
    },
    updateDeviceListShow: (state, action) => {
      const { payload: {
        devicesList,
        show
      } } = action
      state.list = state.list.map(savedDevice => {
        if (devicesList.indexOf(savedDevice.id) > -1) {
          const auxDevice = {...savedDevice, isOpen: show}
          return auxDevice;
        }
        return savedDevice
      })
    },
    confirmLoad: (state) => {
      state.isLoaded = true
    },
    resetDevices: (state) => {
      state.list = []
      state.isLoaded = false
    }
  }
})

export const {
  addDevice,
  updateDevice,
  removeDevice,
  confirmLoad,
  resetDevices,
  updateDeviceShow,
  updateDeviceListShow
} = devicesSlice.actions

export default devicesSlice.reducer
