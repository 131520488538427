import { configureStore, combineReducers } from '@reduxjs/toolkit'
import devicesReducer from './slices/devicesSlice'
import devicesTypesReducer from './slices/devicesTypesSlice'
import userReducer from './slices/userSlice'
import clientsReducer from './slices/clientsSlice'
import thingsReducer from './slices/thingsSlice'
import notificationsReducer from './slices/notificationsSlice'
import logsReducer from './slices/logsSlice'
import schedulesReducer from './slices/schedulesSlice'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const rootReducer = combineReducers({
  devices: devicesReducer,
  user: userReducer,
  clients: clientsReducer,
  devicesTypes: devicesTypesReducer,
  things: thingsReducer,
  notifications: notificationsReducer,
  logs: logsReducer,
  schedules: schedulesReducer
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
})

export const persistor = persistStore(store)
