import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    email: '',
    token: '',
    isAdmin: false,
    logged: false
  },
  reducers: {
    logInUser: (state, action) => {
      const {
        payload: {
          email,
          token,
          isAdmin
        }
      } = action
      state.email = email
      state.token = token
      state.isAdmin = isAdmin
      state.logged = true
    },
    logOutUser: (state) => {
      state.email = ''
      state.token = ''
      state.logged = false
    }
  }
})

export const { logInUser, logOutUser } = userSlice.actions

export default userSlice.reducer
