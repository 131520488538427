import { createSlice } from '@reduxjs/toolkit'

export const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    list: []
  },
  reducers: {
    setClients: (state, action) => {
      const {
        payload: clientsList
      } = action
      state.list = clientsList
    },
    addClient: (state, action) => {
      const {
        payload: client
      } = action

      state.list.push(client)
    },
    updateClientAttr: (state, action) => {
      const {
        payload: {
          email,
          attrName,
          attrValue
        }
      } = action
      state.list = state.list.map(client => {
        if (client.email === email) {
          client[attrName] = attrValue
        }
        return client
      })
    }
  }
})

export const { setClients, addClient, updateClientAttr } = clientsSlice.actions

export default clientsSlice.reducer
